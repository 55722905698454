
// NOT SCOPED
.battery-code-input-wrapper, .form--intro-form {
    .uk-input {
        border-color : var(--global-color);
        font-weight  : bold;

        &::-webkit-input-placeholder,
        &::placeholder {
            border-color : var(--global-color);
            font-weight  : bold;
            color: var(--global-color);
            opacity: 0.8;
        }
    }

    .uk-form-controls .uk-form-icon {
        color             : var(--global-color);
        margin-inline-end : 10px;

        @media (min-width : 1200px) {
            display : none;
        }
    }
}

// we override like this to allow meeting design requirements with a single slot output
.scan-page .info-block .title-separator {
    .content-text {
        background : transparent !important;
    }

    @media (max-width : 1200px) {
        .content {
            color : var(--global-background) !important;

            .content-text h2 {
                color : var(--global-background) !important;
            }
        }

        .line {
            background-color : var(--global-background) !important;
        }
    }

    @media (min-width : 1200px) {
        .content {
            color : var(--global-color) !important;
        }

        .line {
            background-color : var(--global-color) !important;
        }
    }


}

